.container {
  position: absolute;
  width: 15rem;
  background: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin: 3.5rem 0;
  padding: 1em 2em 2em;
  top: 2rem;
  right: 0rem;

  &::before {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    top: -10px;
    left: 6.9rem;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #fff;
  }

  & .box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    gap: 1rem;
    width: 100%;
    height: 100%;

    & .SecOne {
      & p {
        color: #6f10a2;
        font-weight: bold;
        font-size: 0.8rem;
        line-height: 1.5rem;
        text-align: center;
      }
    }

    & .SecTwo {
      position: relative;
      height: 11.25rem;
    }

    & .SecThree {
      position: relative;
      height: 3.6rem;
      cursor: pointer;
    }
    & .SecFour {
      position: relative;
      height: 3.6rem;
      cursor: pointer;
    }
  }
}
