@import '../../../styles/globalVariables';

.profileView {
  position: relative;

  i {
    color: $Cool-Grey-900;
  }

  @include for-size(tablet-up) {
    border: 1px solid $Cool-Grey-100;
    border-radius: 8px;
    padding: $pad8 $pad12;
  }

}

.navbar {
  display: flex;
  justify-content: space-between;

  @include for-size(tablet-up) {
    order: 3;
    font-weight: $default-font-weight;
    margin-left: auto;

    html[dir='rtl'] & {
      margin-left: 0px;
      margin-right: auto;
    }
  }

  @media screen and (max-width: 1023px) {
    gap: 16px;
  }

  @media screen and (max-width: 575px) {
    margin-left: auto;
    gap: 0px;

    html[dir='rtl'] & {
      margin-left: 0;
      margin-right: auto;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    gap: 24px;

    & .actionBtns {
      position: relative;

      & .downloadAppBtn {
        background-color: $white;
        border: none;
        font-weight: $font-semi-bold;
        font-size: $font16;
        color: $color-grey-600;
        line-height: 24px;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 16px;
        padding: $pad8;

        @include for-size(tablet-up) {
          font-weight: $default-font-weight;
          padding: $pad8 $pad16;
          height: 40px;
          gap: 8px;

          i {
            display: flex;
          }
        }

        @media (max-width: 1400px) {
          margin-right: 5px;
          display: none;
        }

        &:hover {
          color: $violet;
        }

        @media only screen and (max-width: 1299px) {
          display: none;
        }
      }

      & .homedownloadAppBtn {
        margin-right: 20px;
        background: #f2f2f3;
        background-color: transparent;
        border: none;
        border-radius: 8px;
        font-weight: bold;
        font-size: 1rem;
        line-height: 1.5rem;
        padding: 0.5em 1em;
        color: $white;
        cursor: pointer;
        white-space: nowrap;

        @media (max-width: 1024px) {
          display: none;
        }
      }
    }
  }
}

.profileText {
  width: max-content;
}

.navbar__link {
  font-size: $font16;
  text-decoration: none;
  color: $color-grey-600;
  font-weight: $font-semi-bold;
  line-height: $lineH24;
  cursor: pointer;
}

.homenavbar__link {
  font-size: 13px;
  text-decoration: none;
  color: $white;
  font-weight: bold;
  cursor: pointer;
}

.language {
  a {
    display: inline-flex;
    flex-flow: row-reverse;
    align-items: center;
    gap: 3px;
    padding: $pad8 $pad0;
  }

  i {
    display: flex;
  }

  @media (max-width: 991px) {
    display: none;
  }
}

html[dir='rtl'] {
  .navbar {
    &__item {
      & .actionBtns {
        & .downloadAppBtn {
          margin-right: 0px;
          white-space: nowrap;
        }

        & .homedownloadAppBtn {
          margin-right: 0px;
          margin-left: 20px;
          white-space: nowrap;
        }
      }
    }
  }

  .language {
    svg {
      margin-top: -2px;
    }
  }

  .propBtns {
    padding-left: 0;
    padding-right: 16px;
    white-space: nowrap;
    height: 48px;

    svg {
      margin-left: -7px;
    }
  }

  .addProperty {
    max-height: 68px;
    margin-top: 5px;
  }

  @media (max-width: 991px) {
    .language {
      left: 2.5vw;
      right: auto;
    }
  }


  @media (min-width: 768px) and (max-width: 991px) {
    .profileView {
      right: auto;
      top: 2vw;
      left: 2vw;
    }

    .language {
      left: 8.5vw;
      top: 27px;
    }

    .addProperty {
      width: 150px;
      position: fixed;
      left: 166px;
      top: 5px;
    }

    .propBtns {
      svg {
        margin-left: -6px;
        margin-right: 0;
      }
    }
  }
}

.propBtns {
  padding-left: 16px;
  border-radius: 12px;
  place-content: center;
  place-items: center;
  display: flex;
  white-space: nowrap;
  height: 48px;
  cursor: pointer;
}

.addProperty {
  width: 180px;
  place-content: center;
  place-items: center;
  display: flex;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
  background: #6f10a2;
  color: #fff;
  border-radius: 12px;
  padding: 0 16px;
  gap: 12px;

  @include for-size(tablet-up) {
    padding: $pad8 $pad16;
    font-weight: $font-semi-bold;
    height: auto;
    gap: 4px;
  }

  html[dir='rtl'] & {
    @include for-size(tablet-up) {
      padding: $pad8 $pad16;
      font-weight: $font-semi-bold;
      height: auto;
      gap: 4px;
    }
  }
}

.profile {
  display: none;
  padding: 7px 11px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 40px;
  border: 1px solid $color-grey-600;
  width: 80px;
  height: 40px;
  cursor: pointer;

  i {
    display: flex;
    color: $Cool-Grey-900;
  }

  @include for-size(tablet-up) {
    gap: 12px;
    border: 1px solid $Cool-Grey-100;
    border-radius: 8px;
    width: 84px;
    justify-content: space-between;
    padding: 3px 3px 3px 12px;

    html[dir='rtl'] & {
      padding: 3px 12px 3px 3px;
    }

    span {
      width: 32px;
      height: 32px;
      border-radius: 4px;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      &:has( :global .icon-loggedin-profile) {
        background-color: $matchmaker-autofill-color;
      }

      :global {
        .icon-loggedin-profile {
          color: $violet;
        }
      }
    }
  }

  @include for-size(tablet-landscape-up) {
    display: flex;
  }
}

.colorInTextWhite {
  color: #ffffff;
}

.colorInText {
  color: #6f10a2;
}

@media screen and (max-width: 991px) {
  .desktopview {
    display: none;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .addProperty {
    height: 46px;
  }

  .propBtns {
    height: 40px !important;

    svg {
      margin-top: 3px;
      margin-right: -6px;
    }
  }
}

@media screen and (min-width: 992px) {
  .desktopview {
    display: flex;
  }
}

@media (max-width: 991px) {
  .profileView {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .profileText {
    display: none;
  }
}

@media (max-width: 578px) {
  .profileView {
    display: block;
    order: 2;
  }
}

@media (max-width: 527px) {
  .profileText {
    display: none;
  }
}

[dir='rtl'] {
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .addProperty {
      width: auto;
      padding: 0px;
    }

    .navbar {
      &__item {
        gap: 20px;
      }
    }
  }
}

@include for-size(tablet-up) {
  .isNotMobile {
    display: none;
  }
}

@include for-size(phone-only) {
  .isNotDesktop {
    display: none;
  }
}